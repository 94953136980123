import React, { useEffect } from 'react';
import "../../css/common.css";
import "../../css/spring/location_spring.css";
import copyImg from '../../css/copy.png';
import KakaoMap from '../KakaoMap';
import tmapLogo from '../../css/logo_tmap.png';
import kakakoMapLogo from '../../css/logo/logo_kakaomap.png';
import naverMapLogo from '../../css/logo/logo_navermap.png';
import { col } from 'framer-motion/client';

const Location_Spring = ({ place, color, fontFamily, fontFamilyKo }) => {
    const address_info = "부산광역시 남구 황령대로 401-9(대연동)";
    const lng = 129.100265672;
    const lat = 35.145592665;

    const handleCopyClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert("주소가 복사되었습니다 조심히 오세요🤍");
        } catch (err) {
            console.log(err);
        }
    };

    // goToKakaoNavi 함수 정의
    const goToKakaoNavi = () => {
        if (window.Kakao) {
            console.log('ssss')
            window.Kakao.Navi.start({
                name: '그랜드모먼트 유스호스텔',
                x: lng,
                y: lat,
                coordType: 'wgs84',
            });
        } else {
            console.error('Kakao SDK 로드 실패');
        }
    };

    return (
        <div className='location_container' style={{fontFamily: fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: color, fontFamily: fontFamily}}>LOCATION</h3>
            <p className='p1_score_14' style={{ paddingTop: "31px", paddingBottom: "10px" , fontFamily: fontFamilyKo}}>{place}</p>
            <div className='location_box'>
                <div className='address_info'  style={{fontFamily: fontFamilyKo}}>
                    <p style={{ fontSize: "11px", marginRight: "2px" }}>{address_info}</p>
                    <img className='copy_img' src={copyImg} alt='Copy' onClick={() => handleCopyClipBoard(address_info)} />
                </div>
                <p style={{ fontSize: "9px" }}>TEL. 051-922-2210</p>
            </div>

            <div style={{ display: "block", flexDirection: "column" }}>
                <KakaoMap lng={lng} lat={lat} />
            </div>

            <div className='navi_box'>
                {/* <div className='navi_btn'>
                    <img className='navi_logo_img' src={tmapLogo} alt='Tmap Logo' />
                    <p>티맵</p>
                </div> */}
                <div className='navi_btn' onClick={goToKakaoNavi}>
                    <img className='navi_logo_img' src={kakakoMapLogo} alt='Kakao Map Logo' style={{ width: "14px", marginRight: "5px" }} />
                    <p>카카오 네비</p>
                </div>
                <div className='navi_btn'>
                    <img className='navi_logo_img' src={naverMapLogo} alt='Naver Map Logo' style={{ width: "11px" }} />
                    <p>네이버 지도</p>
                </div>
            </div>
        </div>
    );
};

export default Location_Spring;
