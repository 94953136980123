import React, { useState, useCallback, useEffect } from 'react';
import "../../css/spring/gallery_spring.css";
import "../../css/spring/message_spring.css";
import "../../css/common.css";
import letterImg from '../../css/letter.png';
import line from '../../css/line.png';
import moment from 'moment-timezone';
import GuestMessageCreateModal from '../Modal/GuestMessageCreateModal';


const Message_Spring = ({goToGuestBook, weddingDate, color, buttonBgColor, fontColor, fontFamilyKo}) => {
    const targetDate = moment.tz(weddingDate, "YYYY-MM-DD", "Asia/Seoul");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [leftTime, setLeftTime] = useState(""); 

    const message_explain = "웨딩 케이크를 메세지로 가득 채워주세요.\n작성된 방명록은 결혼식 당일 열람할 수 있습니다.";

    const calculateTimeLeft = useCallback(() => {
        const now = moment.tz("Asia/Seoul");
        const difference = targetDate.diff(now);

        let timeLeft = "";
        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            if (days > 0) {
                timeLeft = `${days}일`; // 남은 일이 있을 때
            } else if (hours > 0) {
                timeLeft = `${hours}시간`; // 남은 시간이 있을 때
            } else if (minutes > 0) {
                timeLeft = `${minutes}분`; // 남은 분이 있을 때
            } else {
                timeLeft = `${seconds}초`; // 남은 초가 있을 때
            }
        } else {
            timeLeft = "결혼식이 이미 진행되었습니다."; // 결혼식이 지난 경우
        }
        return timeLeft;
    }, [targetDate]);

    // 남은 시간 계산 및 상태 업데이트
    useEffect(() => {
        const intervalId = setInterval(() => {
            setLeftTime(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 정리
    }, [calculateTimeLeft]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const tmpCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'; // 스크롤 비활성화
        } else {
            document.body.style.overflow = 'auto'; // 스크롤 활성화
        }
        return () => {
            document.body.style.overflow = 'auto'; // 컴포넌트가 언마운트될 때 스크롤 활성화
        };
    }, [isModalOpen]);

    return (
        <div className='message_container' style={{fontFamily: fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: `${color}`}}>MESSAGE</h3>
            <p className='p1_score_14' style={{fontFamily: fontFamilyKo}}>방명록</p>
            <img style={{width: "90px"}} src={letterImg} alt='Line Heart Image' />
            <p className='message_explain'>{message_explain}</p>
            <p className='left_time_info'>방명록 열람까지 <span style={{color : color}}>{leftTime}</span> 남았습니다.</p>

            <div style={{display : "flex", width: "280px", justifyContent: "space-around"}}>
                <button className='spring_btn' style={{width: "125px", backgroundColor: buttonBgColor, color:fontColor, fontFamily:fontFamilyKo }} onClick={goToGuestBook}>전체보기</button>
                <button className='spring_btn' style={{width: "125px", backgroundColor: buttonBgColor, color:fontColor, fontFamily:fontFamilyKo }} onClick={openModal}>작성하기</button>
            </div>

            <img className='line_img' src={line} alt='Line Image' />

            {isModalOpen && (
                <GuestMessageCreateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tmpCloseModal={tmpCloseModal} fontFamilyKo={fontFamilyKo} />
            )}

        </div>
    );
};

export default Message_Spring;
