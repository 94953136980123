import React from 'react';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Account_Spring from '../component/Spring/Account_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Main_Fall from '../component/Fall/Main_Fall';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import Info_Fall from '../component/Fall/Info_Fall';


const Summer = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const weddingDate = "2025-03-09";
    const year = "2025";
    const month = "03";
    const date = "09";
    const dayOfWeek = "SUN";
    const time = "12:00";
    const place = "그랜드 모먼트 화이트홀";
    
    const brideName = "김가영";
    const brideNameEn = "Kim Ka young";
    const brideAccountBank = "카카오뱅크"
    const brideAccount = "123-456-78910"

    const groomName = "최정호";
    const groomNameEn = "Choi Jeong ho";
    const groomAccountBank = "신한은행"
    const groomAccount = "110-489-78910"

    const msg1 = "국화향 가득한 길 ,\n 저희 두 사람 \n 이제 사랑으로 하나되어 \n한 길을 가고자 합니다.";
    const msg2 = "가까이서 저희들의 출발을 지켜봐 주시면 \n 더 없는 기쁨으로 간직하겠습니다.";


    // 성을 제외하고 이름만 추출
    const getFirstName = (fullName) => {
        const nameParts = fullName.split(''); // 문자열을 띄어쓰기로 분리
        return nameParts.slice(1).join(''); // 성 빼고 나머지 부분 반환
    };

    // 문자열을 대문자로 변환하는 함수
    const toUpperCaseString = (str) => {
        return str.toUpperCase();
    };

    // 문자열의 모든 공백을 제거하는 함수
    const removeSpaces = (str) => {
        return typeof str === 'string' ? str.replace(/\s+/g, '') : ''; // 모든 공백 제거
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    return (
        <div>
            <Main_Fall brideNameEn={brideNameEn} groomNameEn={groomNameEn} year ={year} month={month} date={date} dayOfWeek={dayOfWeek} time={time} color={"#AB5934"}/>
            <Save_Date_Fall year ={year} month={month} date={date} color={"#AB5934"}/>
            <Info_Fall brideName={brideName} groomName={groomName} place={place} year ={year} month={month} date={date} dayOfWeek={dayOfWeek} time={time}/>
            <Letter msg1={msg1} msg2={msg2} color={"#000"} fontFamily={"JejuMyeongjo"}/>

            <Dday_Spring weddingDate = {weddingDate} brideName={getFirstName(brideName)} groomName={getFirstName(groomName)} color={"#AB5934"} fontColor={"#fff"} buttonBgColor={"#AB5934"} fontFamilyKo={"JejuMyeongjo"}/>
            <MotionWrapper initialY={50}>
                    <Gallery_Spring color={"#000"} fontFamily={"Milchella"} fontFamilyKo={"JejuMyeongjo"}/>
                    <MotionWrapper initialY={70}>
                        <Account_Spring brideName={brideName} groomName={groomName} brideAccountBank={brideAccountBank} brideAccount={brideAccount} groomAccountBank={groomAccountBank} groomAccount={groomAccount} color={"#000"} fontFamily={"Milchella"} fontFamilyKo={"JejuMyeongjo"}/>
                        <MotionWrapper initialY={70}>
                            <Message_Spring goToGuestBook={goToGuestBook} weddingDate = {weddingDate} color={"#000"} buttonBgColor={"#AB5934"} fontColor={"#fff"} fontFamily={"Milchella"} fontFamilyKo={"JejuMyeongjo"}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring  brideName={getFirstName(brideName)} groomName={getFirstName(groomName)} mainColor={"#000"} msgColor={"#AB5934"} bgColor={"#F4F4F4"} fontColor={"#fff"} fontFamily={"Milchella"} fontFamilyKo={"JejuMyeongjo"}/>
                                <Location_Spring place={place} color={"#000"} fontFamily={"Milchella"} fontFamilyKo={"JejuMyeongjo"}/>
                                <Footer/>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper> 
        </div>
    );
};

export default Summer;