import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DynamicPage from './DynamicPage';

function App() {

    return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/:mainPage" element={<DynamicPage />} />
                <Route path="/:mainPage/:subPage" element={<DynamicPage />} />
            </Routes>
        </Router>
    </div>
    );
}

export default App;
