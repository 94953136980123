import React from 'react';
import mainImg from '../../css/summer/summer_main.png';
import '../../css/summer/main.css';

const Main_Summer = ({year, month, date, dayOfWeek, time, color}) => {
    return (
        <div className='summer_main_container'>
                <img className='main_summer' src={mainImg} alt='Main Image'></img>


            <div className="welcome_summer" style={{color : `${color}`}}>
                <p style={{fontSize: "15px"}}>Welcome to</p>
                <p style={{fontSize: "21px"}}>Kim kayoung & Choi jeongho</p>
            </div>

            <div className='main_date_box_summer' color='#fff'>
                <p>{year}.{month}.{date} {dayOfWeek} {time}</p>
            </div>
        </div>
    );
};

export default Main_Summer;