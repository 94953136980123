import React from 'react';
import "../../css/spring/gallery_spring.css"
import "../../css/spring/account_spring.css"
import "../../css/common.css"
import line from '../../css/line.png';
import copyImg from '../../css/copy.png';
import kakaopayImg from '../../css/kakaopay.png';

const Account_Spring = ({brideName, groomName, brideAccountBank, brideAccount, groomAccountBank, groomAccount, color, fontFamily, fontFamilyKo}) => {
    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const handleCopyClipBoard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            alert("계좌번호가 복사되었습니다🤍");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className='basic_column_container' style={{fontFamily: fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: color, fontFamily: fontFamily}}>ACCOUNT NUMBER</h3>
            <p className='p1_score_14' style={{fontFamily: fontFamilyKo}}>마음 전하실 곳</p>
            <p className='p1_score_12' style={{fontFamily: fontFamilyKo}}>{account_explain}</p>
        
            <div className='account_card'>
                <div className='account_name'>
                    <p>신랑 {groomName}</p>
                </div>
                <div className='account_box'>
                    <span>
                        <p>{groomAccountBank}</p> <p>{groomAccount}</p>
                    </span>
                    <span>
                        <img className='copy_img' src={copyImg} alt='Copy Image' onClick={() => handleCopyClipBoard(groomAccount)}></img>
                        <img className='kakaopay_img' src={kakaopayImg} alt='Kakaopay Image'></img>
                    </span>
                </div>
            </div>

            <div className='account_card'>
                <div className='account_name'>
                    <p>신부 {brideName}</p>
                </div>
                <div className='account_box'>
                    <span>
                        <p>{brideAccountBank}</p> <p>{brideAccount}</p>
                    </span>
                    <span>
                        <img className='copy_img' src={copyImg} alt='Copy Image' onClick={() => handleCopyClipBoard(brideAccount)}></img>
                        <img className='kakaopay_img' src={kakaopayImg} alt='Kakaopay Image'></img>
                    </span>
                </div>
            </div>

            <img className='line_img' src={line} alt='Line Image'></img>
        </div>
    );
};

export default Account_Spring;