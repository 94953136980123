import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  
import Main_Spring from '../component/Spring/Main_Spring';
import axios from 'axios';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import MotionWrapper from '../component/MotionWrapper';
import Gallery_Spring from '../component/Spring/Gallery_Spring';

const Kayoung = () => {
    const API_URL = process.env.REACT_APP_API_URL;

    const location = useLocation();
    const navigate = useNavigate();

    const [brideNameKo, setBrideNameKo] = useState("");
    const [brideNameEn, setBrideNameEn] = useState("");
    const [groomNameKo, setGroomNameKo] = useState("");
    const [groomNameEn, setGroomNameEn] = useState("");
    const [weddingDate, setWeddingDate] = useState("");
    const [dayOfWeek, setDayOfWeek] = useState("");
    const [time, setTime] = useState("");
    const [place, setPlace] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [date, setDate] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const msg1 = "봄 햇살이 함께 했던 지난날, \n 여러 해의 봄날 처럼 \n 앞으로도 햇살같은 봄날을 함께 하려 합니다.";
    const msg2 = "부디 귀한 시간 내주시어 저희의 봄날에 함께 \n 축복 해주시면 감사하겠습니다.";

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info${location.pathname}`);
            console.log(response);
            if(response.status === 200){
                setBrideNameKo(response.data.data.brideNameKo);
                setBrideNameEn(response.data.data.brideNameEn);
                setGroomNameKo(response.data.data.groomNameKo);
                setGroomNameEn(response.data.data.groomNameEn);
                setWeddingDate(response.data.data.weddingDate);
                setDayOfWeek(response.data.data.dayOfWeek);
                setTime(response.data.data.time);
                setPlace(response.data.data.place);
                setIsSuccess(true); 
            }
        } catch (error) {
            console.error("API 요청 실패:", error);
        } finally{
            console.log('isSuccess 상태:', isSuccess);
            console.log('brideNameEn:', brideNameEn);
            console.log('groomNameEn:', groomNameEn);
            console.log('wedding : ', weddingDate)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (weddingDate) {
            const [a, b, c] = weddingDate.split('-');
            setYear(a);
            setMonth(b);
            setDate(c);
        }
    }, [weddingDate]);

    // 성을 제외하고 이름만 추출
    const getFirstName = (fullName) => {
        const nameParts = fullName.split(''); // 문자열을 띄어쓰기로 분리
        return nameParts.slice(1).join(''); // 성 빼고 나머지 부분 반환
    };

    // 문자열의 모든 공백을 제거하는 함수
    const removeSpaces = (str) => {
        return typeof str === 'string' ? str.replace(/\s+/g, '') : ''; // 모든 공백 제거
    };

    return (
        <div>
            {isSuccess && (
                <>
                <Main_Spring brideNameEn={brideNameEn.toUpperCase()} groomNameEn={groomNameEn.toUpperCase()} year={year} month={month} date={date} dayOfWeek={dayOfWeek} time={time} color={"#FF8A83"}/>
                <Info_Spring brideNameEn = {removeSpaces(brideNameEn)} groomNameEn={removeSpaces(groomNameEn)} brideName={brideNameKo} groomName={groomNameKo} year ={year} month={month} date={date} place={place} ayOfWeek={dayOfWeek} time={time} color={"#FF8A83"}/>               
                <Letter msg1={msg1} msg2={msg2} color={"#FF8A83"}/>
                <Dday_Spring weddingDate = {weddingDate} brideName={getFirstName(brideNameKo)} groomName={getFirstName(groomNameKo)} color={"#FF8A83"}/>
                <MotionWrapper initialY={50}>
                    <Gallery_Spring/>
                </MotionWrapper>
                </>
            )}
        </div>
    );
};

export default Kayoung;
