import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Account_Spring from '../component/Spring/Account_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';

const Spring = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [weddingData, setWeddingData] = useState({
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:''
    });
    const [conceptStyle, setConceptStyle] = useState(null);

    const conceptStyles = [
        {
            concept: "SPRING",
            mainColor: "#FF8A83",
            buttonBgColor: "#FFE8E7",
            fontColor: "#313131",
            btnFontColor: "#FF8A83",
            chatBgColor: "#fefaf9",
            fontFamilyKo: "SCDream3",
            fontFamilyEn: "Arial"
        },
        {
            concept: "SUMMER",
            mainColor: "#FFB84D",
            buttonBgColor: "#FFEBCC",
            fontColor: "#2C2C2C",
            btnFontColor: "#FFB84D",
            chatBgColor: "#FFF7E5",
            fontFamilyKo: "NotoSansKR",
            fontFamilyEn: "Verdana"
        },
        {
            concept: "FALL",
            mainColor: "#FFA500",
            buttonBgColor: "#FFD699",
            fontColor: "#5B4D36",
            btnFontColor: "#FFA500",
            chatBgColor: "#FFF4E6",
            fontFamilyKo: "NanumGothic",
            fontFamilyEn: "Times New Roman"
        }
    ];

    
    const brideNameEn = "Ka young";
    const brideAccountBank = "카카오뱅크"
    const brideAccount = "123-456-78910"

    const groomNameEn = "Jeong ho";
    const groomAccountBank = "신한은행"
    const groomAccount = "110-489-78910"

    const msg1 = "봄 햇살이 함께 했던 지난날, \n 여러 해의 봄날 처럼 \n 앞으로도 햇살같은 봄날을 함께 하려 합니다.";
    const msg2 = "부디 귀한 시간 내주시어 저희의 봄날에 함께 \n 축복 해주시면 감사하겠습니다.";

    const getFirstName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join('');
    };

    const toUpperCaseString = (str) => {
        return str.toUpperCase();
    };

    const removeSpaces = (str) => {
        return typeof str === 'string' ? str.replace(/\s+/g, '') : ''; 
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
    
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
            console.log('stope');
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            console.log("response: ", response);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place
                    };

                    const concept = response.data.data.concept;
                    const matchingStyle = conceptStyles.find(style => style.concept === concept);
                    setConceptStyle(matchingStyle);  

                    console.log("Updated weddingData:", newData);
                    console.log("concept style: ", conceptStyle);
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패1:", error.response ? error.response.data : error.message);
        }
    };

    if (!weddingData || !conceptStyle) return <div>Loading...</div>;
    return (
        <div>
            <Main_Spring brideNameEn={toUpperCaseString(brideNameEn)} groomNameEn={toUpperCaseString(groomNameEn)} year={weddingData.year} month={weddingData.month} date={weddingData.date} dayOfWeek={weddingData.dayOfWeek} time={weddingData.time} color={conceptStyle.mainColor}/>
            <Save_Date color={"#FF8A83"} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring brideNameEn={removeSpaces(brideNameEn)} groomNameEn={removeSpaces(groomNameEn)} brideName={weddingData.brideName} groomName={weddingData.groomName} place={weddingData.place} year={weddingData.year} month={weddingData.month} date={weddingData.date} dayOfWeek={weddingData.dayOfWeek} time={weddingData.time} color={conceptStyle.mainColor}/>               
            <Letter msg1={msg1} msg2={msg2} color={"#FF8A83"}/>
            <Dday_Spring weddingDate={weddingData.weddingDate} brideName={getFirstName(weddingData.brideName)} groomName={getFirstName(weddingData.groomName)} color={"#FF8A83"} fontColor={"#313131"} buttonBgColor={"#FFE8E7"}/>
                <MotionWrapper initialY={50}>
                    <Gallery_Spring color={"#FF8A83"}/>
                    <MotionWrapper initialY={70}>
                        <Account_Spring brideName={weddingData.brideName} groomName={weddingData.groomName} brideAccountBank={brideAccountBank} brideAccount={brideAccount} groomAccountBank={groomAccountBank} groomAccount={groomAccount} color={"#FF8A83"}/>
                        <MotionWrapper initialY={70}>
                            <Message_Spring goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} color={"#FF8A83"} buttonBgColor={"#FFE8E7"} fontColor={"#313131"}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring brideName={getFirstName(weddingData.brideName)} groomName={getFirstName(weddingData.groomName)} mainColor={"#FF8A83"} msgColor={"#FFD1CF"} bgColor={"#fefaf9"} fontFamilyKo={"SCDream3"}/>
                                <Location_Spring place={weddingData.place} color={"#FF8a83"}/>
                                <Footer/>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
        </div>
    );
};

export default Spring;
