import React, { useState, useEffect } from 'react';
import ModalTemplate from '../ModalTemplate';
import GuestMessageUpdateModal from './GuestMessageUpdateModal';

const GuestMessageValidateModal = ({ isModalOpen, tmpCloseModal, buttonBgColor, fontColor, fontFamilyKo}) => {
    const [formData, setFormData] = useState({ name: '', password: '' });
    const [isFormComplete, setIsFormComplete] = useState(false); 
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const goToUpdateModal = () => {
        setUpdateModalOpen(true);
    }

    const tmpCloseUpdateModal = () => {
        setUpdateModalOpen(false);
    };

    useEffect(() => {
        const isAllFieldsFilled = Object.values(formData).every(value => value !== '');
        setIsFormComplete(isAllFieldsFilled);
    }, [formData]);

    return (
        <div>
            <ModalTemplate
                isOpen={isModalOpen}
                title="MESSAGE"
                description="정보를 입력해주세요."
                tmpCloseModal={tmpCloseModal}
                fontFamilyKo={fontFamilyKo}
            >
                <div className='form_group'>
                    <input
                        type='text'
                        name='name' 
                        placeholder='작성자'
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <input
                        type='password'
                        name='password'
                        placeholder='휴대폰번호 뒷자리'
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                </div>
                <button 
                    className='spring_btn' 
                    onClick={goToUpdateModal} 
                    disabled={!isFormComplete} 
                    style={{width : "219px", marginTop:"10px", backgroundColor:buttonBgColor, color:fontColor, fontFamily: fontFamilyKo}}
                >
                    다음
                </button>
            </ModalTemplate>

            {isUpdateModalOpen && (
                <GuestMessageUpdateModal isModalOpen={isUpdateModalOpen} setIsModalOpen={setUpdateModalOpen} tmpCloseModal={tmpCloseUpdateModal} fontFamilyKo={fontFamilyKo}/>
            )}
        </div>
    );
};

export default GuestMessageValidateModal;