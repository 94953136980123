import React, { useEffect } from 'react';
import "../../css/main_spring.css"
import "../../css/spring/main.scss"
import mainImg from '../../css/main_img.png';
import AutoPlayAudio from '../AutoPlayAudio';

const Main_Spring = ({brideNameEn, groomNameEn, year, month, date, dayOfWeek, time, color}) => {

    return (
        <div className='main_container' style={{color : `${color}` ,'--svg-fill-color': color}}>
            <AutoPlayAudio/>
            <div className='main_name_box'>
                <p>{groomNameEn}</p>
                <p>{brideNameEn}</p>
            </div>
            <div style={{ position: 'relative' }}>
                <img className='main_img' src={mainImg} alt='Main Image'></img>

                <div className='main_text'>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        {['H', 'a', 'p', 'p', 'i', 'l', 'y'].map((letter, index) => {
                            var xPosition = 0;
                            if(index === 0) {
                                xPosition = 0
                            } else if(index ===1){
                                xPosition = 85
                            } else {
                                xPosition = index * 37 + 42; 
                            }

                            if (index === 6){
                                xPosition -= 15;
                            }

                            return (
                                <text 
                                    key={index} 
                                    className={`svg_text svg_text${index + 1}`} 
                                    x={xPosition} 
                                    y="110" 
                                    fill={color}
                                    style={{ animationDelay: `${index * 0.2}s` }}
                                    >
                                    {letter}
                                </text>
                            );
                        })}
                    </svg>
                </div>

                <div className='main_text_bottom'>
                    <svg xmlns="http://www.w3.org/2000/svg" width={350}>
                        {['E', 'v', 'e', 'r','A','f','t','e','r'].map((letter, index) => {
                            var xPosition = 0;
                            if(index === 0) {
                                xPosition = 0
                            } else if(index ===1){
                                xPosition = 70
                            } else {
                                xPosition = index * 37 + 24; 
                            }

                            if(index === 3){
                                xPosition -= 10
                            }

                            if(index >=5){
                                if(index ===5){
                                    xPosition += 40
                                } else if(index === 6) {
                                    xPosition += 30
                                } else if(index === 7) {
                                    xPosition += 10
                                } 
                            }

                            return (
                                <text 
                                    key={index} 
                                    className={`svg_text svg_text${index + 1}`} 
                                    x={xPosition} 
                                    y="115" 
                                    fill={color}
                                    style={{ animationDelay: `${index * 0.2 + 1.4}s`}}
                                    >
                                    {letter}
                                </text>
                            );
                        })}
                    </svg>
                </div>

            </div>

            <div className='main_date_box'>
                <p>{year}.{month}.{date} {dayOfWeek} {time}</p>
            </div>
        </div>
    );
};

export default Main_Spring;