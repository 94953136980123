import React, { useState, useEffect } from 'react';
import ModalTemplate from '../ModalTemplate';
import { useNavigate } from 'react-router-dom';

const GuestMessageUpdateModal = ({ isModalOpen, setIsModalOpen, tmpCloseModal, invitationInfoId, fontFamilyKo}) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [formData, setFormData] = useState({ name: '', message: '' });
    const [isFormComplete, setIsFormComplete] = useState(false); // 폼 완료 여부 상태

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        // 모든 필드가 채워졌는지 확인
        const isAllFieldsFilled = Object.values(formData).every(value => value !== '');
        setIsFormComplete(isAllFieldsFilled);
    }, [formData]);

    const submitMessage = () => {
        const requestData = {
            name: formData.name,
            message: formData.message,
            invitationId: 1
        };

        // fetch(`${API_URL}/_api/v1/guest_book`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(requestData),
        // })
        // .then(response => {
        //     if (!response.ok) {
        //         return response.json();
        //     }
        //     tmpCloseModal();
        //     navigate('/guest'); 
        // })
        // .catch(error => {
        //     console.error('에러 발생:', error.message);
        // });
    };


    return (
        <div>
            <ModalTemplate
                isOpen={isModalOpen}
                title="MESSAGE"
                description="방명록을 작성해 주세요."
                tmpCloseModal={tmpCloseModal}
                fontFamilyKo={fontFamilyKo}
            >
                <div className='form_group'>
                    <input
                        type='text'
                        name='name' 
                        placeholder='작성자'
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <textarea
                        name='message'
                        placeholder='방명록을 작성해 주세요.'
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                </div>
                <button 
                    className='spring_btn' 
                    onClick={submitMessage} 
                    disabled={!isFormComplete} 
                    style={{width : "219px", marginTop:"10px", fontFamily: fontFamilyKo}}
                >
                    전달하기
                </button>
            </ModalTemplate>
        </div>
    );
};

export default GuestMessageUpdateModal;