import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox"; // 새 라이브러리 import
import "yet-another-react-lightbox/styles.css"; // 스타일 추가

// 라이트 박스 추가 기능 불러오기
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

import re1 from '../css/gallery/Rectangle_1.png';
import re2 from '../css/gallery/Rectangle_2.png';
import re3 from '../css/gallery/Rectangle_3.png';
import re4 from '../css/gallery/Rectangle_4.png';
import re5 from '../css/gallery/Rectangle_5.png';
import re6 from '../css/gallery/Rectangle_6.png';
import '../css/gallery_cards.css';

const images = [
  {
    src: re1,
    title: "Beautiful Wedding Moment",
    // description: "Captured during the wedding ceremony."
  },
  {
    src: re2,
    title: "Precious Memories",
    // description: "A beautiful shot of the couple."
  },
  {
    src: re3,
    title: "The Perfect Day",
    // description: "Memories that will last forever."
  },
  {
    src: re4,
    title: "A Day to Remember",
    // description: "201Memories that will 름 🤍"
  },
  {
    src: re5,
    title: "Celebrating Love",
    // description: "A moment filled with happiness."
  },
  {
    src: re6,
    title: "Cherished Moments",
    // description: "A perfect end to a perfect day."
  },
  {
    src: re4,
    title: "A Day to Remember",
    // description: "Smiles, love, and joy all around."
  },
  {
    src: re5,
    title: "Celebrating Love",
    // description: "A moment filled with happiness."
  },
  {
    src: re6,
    title: "Cherished Moments",
    // description: "A perfect end to a perfect day."
  }
];
const Gallery_Cards = () => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const handleImageClick = (index) => {
        setIndex(index);
        setOpen(true);
    };

    // https://yet-another-react-lightbox.com/documentation
    // https://yet-another-react-lightbox.com/customization
    return (
        <div className='gallery_card_container'>
            {images.map((image, idx) => (
                <img
                    key={idx}
                    className='card_photo'
                    src={image.src}
                    alt={`photo${idx + 1}`}
                    onClick={() => handleImageClick(idx)} // 이미지 클릭 시 모달 열기
                />
            ))}

            {open && (
                <Lightbox
                    open={open}
                    index={index}
                    close={() => setOpen(false)}
                    slides={images}
                    onIndexChange={setIndex} // 스와이프할 때 인덱스 변경
                    styles={{
                        root: { "--yarl__color_backdrop": "rgba(255, 255, 255, .9)"} 
                      }}

                    plugins={[
                        // Fullscreen
                        // , Slideshow
                        // , Thumbnails
                        , Captions
                    ]}
                />
            )}
        </div>
    );
};

export default Gallery_Cards;
