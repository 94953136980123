import React, { useState, useEffect } from 'react';
import ModalTemplate from '../ModalTemplate';
import { useNavigate, useParams } from 'react-router-dom';

const GuestMessageCreateModal = ({ isModalOpen, tmpCloseModal, onSubmit, buttonBgColor, fontColor, invitationInfoId, fontFamilyKo}) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { mainPage } = useParams(); 

    const [formData, setFormData] = useState({ name: '', password: '', message: '' });
    const [isFormComplete, setIsFormComplete] = useState(false); // 폼 완료 여부 상태

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        // 모든 필드가 채워졌는지 확인
        const isAllFieldsFilled = Object.values(formData).every(value => value !== '');
        setIsFormComplete(isAllFieldsFilled);
        console.log("invitationInfoId :  ", invitationInfoId);
    }, [formData]);

    const submitMessage = () => {
        const requestData = {
            name: formData.name,
            password: formData.password,
            message: formData.message,
            invitationId: invitationInfoId

        };

        fetch(`${API_URL}/_api/v1/guest_book`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
        .then(response => {
            console.log("creaate", response);
            if (!response.ok) {
                return response.json();
            }
            tmpCloseModal();
            onSubmit(); 
            // navigate(`/${mainPage}/guest`); 
        })
        .catch(error => {
            console.error('에러 발생:', error.message);
        });
    };


    return (
        <div>
            <ModalTemplate
                isOpen={isModalOpen}
                title="MESSAGE"
                description="방명록을 작성해 주세요."
                tmpCloseModal={tmpCloseModal}
                fontFamilyKo={fontFamilyKo}
            >
                <div className='form_group' style={{fontFamily: fontFamilyKo}}>
                    <input
                        type='text'
                        name='name' 
                        placeholder='작성자'
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <input
                        type='password'
                        name='password'
                        placeholder='휴대폰번호 뒷자리'
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                    <textarea
                        name='message'
                        placeholder='방명록을 작성해 주세요.'
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                </div>
                <button 
                    className='spring_btn' 
                    onClick={submitMessage} 
                    disabled={!isFormComplete} 
                    style={{width : "219px", marginTop:"10px", backgroundColor:buttonBgColor, color:fontColor, fontFamily: fontFamilyKo}}
                >
                    전달하기
                </button>
            </ModalTemplate>
        </div>
    );
};

export default GuestMessageCreateModal;