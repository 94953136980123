import React from 'react';
import "../../css/letter.css"
import MotionWrapper from '../MotionWrapper';


const Letter = ({msg1, msg2, msg3, color, fontFamily}) => {

    return (
        <div className='letter_container' style={{fontFamily : fontFamily}}>
            <MotionWrapper> 
                <div className='title' style={{color : `${color}`}}>
                    우리 결혼합니다.
                </div>
            </MotionWrapper>
            <div className='body'>
                <MotionWrapper> 
                    <p className='body1' style={{fontFamily : fontFamily}}>
                        {msg1}
                    </p>
                </MotionWrapper>
                
                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : fontFamily}}>
                        {msg2}
                    </p>
                </MotionWrapper>

                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : fontFamily}}>
                        {msg3}
                    </p>
                </MotionWrapper>
            </div>
        </div>
    );
};

export default Letter;