import React from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Account_Spring from '../component/Spring/Account_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';


const Summer = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const weddingDate = "2025-03-09";
    const year = "2025";
    const month = "03";
    const date = "09";
    const dayOfWeek = "SUN";
    const time = "12:00";
    const place = "그랜드 모먼트 화이트홀";
    
    const brideName = "김가영";
    const brideNameEn = "Ka young";
    const brideAccountBank = "카카오뱅크"
    const brideAccount = "123-456-78910"

    const groomName = "최정호";
    const groomNameEn = "Jeong ho";
    const groomAccountBank = "신한은행"
    const groomAccount = "110-489-78910"

    const msg1 = "싱그러운 여름 향기가 가득한 날,";
    const msg2 = "두 사람이 사랑으로 만나 \n 부부라는 이름으로 마주보기 위해 \n 소중한 분들을 모시고 \n사랑의 약속을 하려고 합니다.";
    const msg3 = "한 곳을 바라보며 \n첫 발을 내밀은 자리 \n 그 시작에 함께해 주시면 \n 더 없는 기쁨으로 간직하겠습니다.";


    // 성을 제외하고 이름만 추출
    const getFirstName = (fullName) => {
        const nameParts = fullName.split(''); // 문자열을 띄어쓰기로 분리
        return nameParts.slice(1).join(''); // 성 빼고 나머지 부분 반환
    };

    // 문자열을 대문자로 변환하는 함수
    const toUpperCaseString = (str) => {
        return str.toUpperCase();
    };

    // 문자열의 모든 공백을 제거하는 함수
    const removeSpaces = (str) => {
        return typeof str === 'string' ? str.replace(/\s+/g, '') : ''; // 모든 공백 제거
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    return (
        <div>
            <Main_Summer year ={year} month={month} date={date} dayOfWeek={dayOfWeek} time={time} color={"#505D89"}/>
            <Save_Date color={"#505D89"} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
            <Info_Spring brideNameEn = {removeSpaces(brideNameEn)} groomNameEn={removeSpaces(groomNameEn)} brideName={brideName} groomName={groomName} place={place} year ={year} month={month} date={date} dayOfWeek={dayOfWeek} time={time} color={"#505D89"}/> 
            <Letter msg1={msg1} msg2={msg2} msg3={msg3} color={"#505D89"}/>
            <Dday_Spring weddingDate = {weddingDate} brideName={getFirstName(brideName)} groomName={getFirstName(groomName)} color={"#505D89"} fontColor={"#fff"} buttonBgColor={"#505D89"}/>
            <MotionWrapper initialY={50}>
                    <Gallery_Spring color={"#505D89"}/>
                    <MotionWrapper initialY={70}>
                        <Account_Spring brideName={brideName} groomName={groomName} brideAccountBank={brideAccountBank} brideAccount={brideAccount} groomAccountBank={groomAccountBank} groomAccount={groomAccount} color={"#505D89"}/>
                        <MotionWrapper initialY={70}>
                            <Message_Spring goToGuestBook={goToGuestBook} weddingDate = {weddingDate} color={"#505D89"} buttonBgColor={"#505D89"} fontColor={"#fff"}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring  brideName={getFirstName(brideName)} groomName={getFirstName(groomName)} mainColor={"#505D89"} msgColor={"#505D89"} bgColor={"#F5F8FF"} fontColor={"#fff"} fontFamilyKo={"SCDream3"}/>
                                <Location_Spring place={place} color={"#505D89"}/>
                                <Footer/>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper> 
        </div>
    );
};

export default Summer;