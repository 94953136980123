import React from 'react';
import mainImg from '../../css/fall/main_fall.png';
import '../../css/fall/main.css';

const Main_Fall = ({brideNameEn, groomNameEn, year, month, date, dayOfWeek, time, color}) => {
    const brideNameParts = brideNameEn.split(" ");
    const formattedBrideName = `${brideNameParts[0]} ${brideNameParts.slice(1).join("")}`;

    const groomNameParts = groomNameEn.split(" ");
    const formattedGroomName = `${groomNameParts[0]} ${groomNameParts.slice(1).join("")}`;

    return (
        <div className='fall_main_container' style={{color: color}}>
            <div className='main_text_box'> 
                <p style={{color: "#4E4E4E", fontSize: "15px", fontFamily: "Milchella", marginTop: "10px"}}>The wedding of</p>
                <span>
                    <p style={{fontSize: "51px", fontFamily: "Cherolina"}}>Forever begine today</p>
                    <p style={{fontSize: "20px", fontFamily: "Ethereal"}}>{formattedGroomName} . {formattedBrideName}</p>
                </span>
            </div>
            <img className='main_fall' src={mainImg} alt='Main Image'></img>

            <p style={{fontSize: "21px", fontFamily: "Milchella"}}>{year}.{month}.{date} {dayOfWeek} {time}</p>
        </div>
    );
};

export default Main_Fall;