import React from 'react';
import { color, motion } from 'framer-motion'; 
import "../../css/common.css"
import "../../css/spring/interview_spring.css"
import bubbleGray from '../../css/bubble/bubble_right_gray.png';
import bubblePink from '../../css/bubble/bubble_left_pink.png';
import line from '../../css/line.png';
import heart from '../../css/interview_heart.png';
import noti_5 from '../../css/noti_5.png';

const MotionWrapper = ({ children, initialY = 70, opacity = 0.3 }) => (
    <motion.div
        initial={{ opacity: opacity, y: initialY }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{
            ease: "easeInOut",
            duration: 0.5, // 애니메이션 지속 시간
        }}
    >
        {children}
    </motion.div>
);

const Interview_Spring = ({ brideName, groomName, mainColor, msgColor, bgColor, fontColor, fontFamily, fontFamilyKo}) => {
    return (
        <div className='basic_column_container' style={{fontFamily: fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: mainColor, fontFamily: fontFamily}}>INTERVIEW</h3>
            <p className='p1_score_12' style={{ padding: "31px 0", fontFamily: fontFamilyKo}}>다섯 글자로 전하는 우리 이야기</p>

            <div style={{ backgroundColor: bgColor, padding: "1.1em", borderRadius: "4px" }}>
                <img className="noti_heart vibration" src={heart} alt='interview_heart_img'></img>
                <img className='noti5' src={noti_5} alt='noti_5'></img>
                
                <MotionWrapper>
                <p className='interview_q' style={{marginBottom: "2rem"}}>정호 가영에게 메세지가 도착했습니다.</p>
                </MotionWrapper>

                <p className='interview_q'>Q. 우리의 설레였던 첫 만남 기억해?</p>
                <div className='bubble_container'>
                    {/* 왼쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box left'>
                            <p className='name'>{groomName}</p>
                            <div className='bubble_wrapper'>
                                <img className='bubble_left' src={bubbleGray} alt='Bubble Image' />
                                <p className='bubble_text left_text'>너무 예뻤어</p>
                            </div>
                        </div>
                    </MotionWrapper>

                    {/* 오른쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box right'>
                            <p className='name'>{brideName}</p>
                            <div className='bubble_wrapper'>
                                <svg className="bubble_right" width="142px" height="35px"  xmlns="http://www.w3.org/2000/svg">
                                    <path fill={`${msgColor}`} d="M128.115 32.2643C129.688 33.2147 132.441 34.6498 135.434 34.9713C136.154 35.0489 137.396 35.117 138.717 35.1028C140.046 35.0901 141.168 34.9967 142 34.9048C138.901 33.316 137.076 31.4406 136.118 30.2827C134.527 28.3613 134.317 27.205 134.271 26.584C134.203 25.6416 134.471 25.2662 134.75 23.8105C134.963 22.7001 134.986 21.5137 135.024 19.1884C135.073 16.1645 135.098 14.6518 134.888 13.5097C134.738 12.7019 134.392 10.8993 133.245 8.88763C131.452 5.73865 128.866 4.11505 127.705 3.407C125.175 1.86102 122.864 1.34781 121.685 1.09595C119.632 0.657185 118.01 0.638177 117.376 0.633425C113.309 0.603329 72.8556 0.536801 17.3734 0.501953C12.7466 1.06902 9.52431 2.4772 7.66046 3.47353C5.88686 4.42076 4.94017 5.21118 4.51358 5.58658C1.7818 7.99743 0.820343 10.7171 0.615265 11.3317C0.173904 12.656 0.0410156 13.8044 0 14.5678C0.0689087 17.4951 0.136169 20.4223 0.205078 23.3511C0.682526 24.8892 1.69812 27.3982 3.96722 29.7568C4.25928 30.0609 4.99266 30.7991 6.08702 31.6053C9.20601 33.9037 12.3151 34.588 14.1577 34.9729C15.444 35.2422 17.2931 35.5225 19.5605 35.502H118.06C119.567 35.3657 121.509 35.0489 123.67 34.314C125.481 33.6962 126.968 32.947 128.116 32.2674L128.115 32.2643Z"/>
                                </svg>
                                <p className='bubble_text right_text' style={{color: fontColor}}>어떻게 잊어</p>
                            </div>
                        </div>
                    </MotionWrapper>
                </div>


                <p className='interview_q'>Q.결혼을 앞둔 소감이?</p>
                <div className='bubble_container'>
                    {/* 왼쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box left'>
                            <p className='name'>{groomName}</p>
                            <div className='bubble_wrapper'>
                                <img className='bubble_left' src={bubbleGray} alt='Bubble Image' />
                                <p className='bubble_text left_text'>너무 떨려요</p>
                            </div>
                        </div>
                    </MotionWrapper>

                    {/* 오른쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box right'>
                            <p className='name'>{brideName}</p>
                            <div className='bubble_wrapper'>
                                <svg className="bubble_right" width="142px" height="35px"  xmlns="http://www.w3.org/2000/svg">
                                    <path fill={`${msgColor}`} d="M128.115 32.2643C129.688 33.2147 132.441 34.6498 135.434 34.9713C136.154 35.0489 137.396 35.117 138.717 35.1028C140.046 35.0901 141.168 34.9967 142 34.9048C138.901 33.316 137.076 31.4406 136.118 30.2827C134.527 28.3613 134.317 27.205 134.271 26.584C134.203 25.6416 134.471 25.2662 134.75 23.8105C134.963 22.7001 134.986 21.5137 135.024 19.1884C135.073 16.1645 135.098 14.6518 134.888 13.5097C134.738 12.7019 134.392 10.8993 133.245 8.88763C131.452 5.73865 128.866 4.11505 127.705 3.407C125.175 1.86102 122.864 1.34781 121.685 1.09595C119.632 0.657185 118.01 0.638177 117.376 0.633425C113.309 0.603329 72.8556 0.536801 17.3734 0.501953C12.7466 1.06902 9.52431 2.4772 7.66046 3.47353C5.88686 4.42076 4.94017 5.21118 4.51358 5.58658C1.7818 7.99743 0.820343 10.7171 0.615265 11.3317C0.173904 12.656 0.0410156 13.8044 0 14.5678C0.0689087 17.4951 0.136169 20.4223 0.205078 23.3511C0.682526 24.8892 1.69812 27.3982 3.96722 29.7568C4.25928 30.0609 4.99266 30.7991 6.08702 31.6053C9.20601 33.9037 12.3151 34.588 14.1577 34.9729C15.444 35.2422 17.2931 35.5225 19.5605 35.502H118.06C119.567 35.3657 121.509 35.0489 123.67 34.314C125.481 33.6962 126.968 32.947 128.116 32.2674L128.115 32.2643Z"/>
                                </svg>
                                <p className='bubble_text right_text' style={{color: fontColor}}>설레 죽겠다</p>
                            </div>
                        </div>
                    </MotionWrapper>
                </div>

                <p className='interview_q'>Q. 서로에게 하고싶은 말은?</p>
                <div className='bubble_container'>
                    {/* 왼쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box left'>
                            <p className='name'>{groomName}</p>
                            <div className='bubble_wrapper'>
                                <img className='bubble_left' src={bubbleGray} alt='Bubble Image' />
                                <p className='bubble_text left_text'>많이 사랑해</p>
                            </div>
                        </div>
                    </MotionWrapper>

                    {/* 오른쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box right'>
                            <p className='name'>{brideName}</p>
                            <div className='bubble_wrapper'>
                                <svg className="bubble_right" width="142px" height="35px"  xmlns="http://www.w3.org/2000/svg">
                                    <path fill={`${msgColor}`} d="M128.115 32.2643C129.688 33.2147 132.441 34.6498 135.434 34.9713C136.154 35.0489 137.396 35.117 138.717 35.1028C140.046 35.0901 141.168 34.9967 142 34.9048C138.901 33.316 137.076 31.4406 136.118 30.2827C134.527 28.3613 134.317 27.205 134.271 26.584C134.203 25.6416 134.471 25.2662 134.75 23.8105C134.963 22.7001 134.986 21.5137 135.024 19.1884C135.073 16.1645 135.098 14.6518 134.888 13.5097C134.738 12.7019 134.392 10.8993 133.245 8.88763C131.452 5.73865 128.866 4.11505 127.705 3.407C125.175 1.86102 122.864 1.34781 121.685 1.09595C119.632 0.657185 118.01 0.638177 117.376 0.633425C113.309 0.603329 72.8556 0.536801 17.3734 0.501953C12.7466 1.06902 9.52431 2.4772 7.66046 3.47353C5.88686 4.42076 4.94017 5.21118 4.51358 5.58658C1.7818 7.99743 0.820343 10.7171 0.615265 11.3317C0.173904 12.656 0.0410156 13.8044 0 14.5678C0.0689087 17.4951 0.136169 20.4223 0.205078 23.3511C0.682526 24.8892 1.69812 27.3982 3.96722 29.7568C4.25928 30.0609 4.99266 30.7991 6.08702 31.6053C9.20601 33.9037 12.3151 34.588 14.1577 34.9729C15.444 35.2422 17.2931 35.5225 19.5605 35.502H118.06C119.567 35.3657 121.509 35.0489 123.67 34.314C125.481 33.6962 126.968 32.947 128.116 32.2674L128.115 32.2643Z"/>
                                </svg>
                                <p className='bubble_text right_text' style={{color: fontColor}}>나도 사랑해</p>
                            </div>
                        </div>
                    </MotionWrapper>
                </div>

                <p className='interview_q'>Q. 유부남/녀 기념 각오 한마디!</p>
                <div className='bubble_container'>
                    {/* 왼쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box left'>
                            <p className='name'>{groomName}</p>
                            <div className='bubble_wrapper'>
                                <img className='bubble_left' src={bubbleGray} alt='Bubble Image' />
                                <p className='bubble_text left_text'>잘해줄게요</p>
                            </div>
                        </div>
                    </MotionWrapper>

                    {/* 오른쪽 말풍선 */}
                    <MotionWrapper>
                        <div className='bubble_box right'>
                            <p className='name'>{brideName}</p>
                            <div className='bubble_wrapper'>
                                <svg className="bubble_right" width="142px" height="35px"  xmlns="http://www.w3.org/2000/svg">
                                    <path fill={`${msgColor}`} d="M128.115 32.2643C129.688 33.2147 132.441 34.6498 135.434 34.9713C136.154 35.0489 137.396 35.117 138.717 35.1028C140.046 35.0901 141.168 34.9967 142 34.9048C138.901 33.316 137.076 31.4406 136.118 30.2827C134.527 28.3613 134.317 27.205 134.271 26.584C134.203 25.6416 134.471 25.2662 134.75 23.8105C134.963 22.7001 134.986 21.5137 135.024 19.1884C135.073 16.1645 135.098 14.6518 134.888 13.5097C134.738 12.7019 134.392 10.8993 133.245 8.88763C131.452 5.73865 128.866 4.11505 127.705 3.407C125.175 1.86102 122.864 1.34781 121.685 1.09595C119.632 0.657185 118.01 0.638177 117.376 0.633425C113.309 0.603329 72.8556 0.536801 17.3734 0.501953C12.7466 1.06902 9.52431 2.4772 7.66046 3.47353C5.88686 4.42076 4.94017 5.21118 4.51358 5.58658C1.7818 7.99743 0.820343 10.7171 0.615265 11.3317C0.173904 12.656 0.0410156 13.8044 0 14.5678C0.0689087 17.4951 0.136169 20.4223 0.205078 23.3511C0.682526 24.8892 1.69812 27.3982 3.96722 29.7568C4.25928 30.0609 4.99266 30.7991 6.08702 31.6053C9.20601 33.9037 12.3151 34.588 14.1577 34.9729C15.444 35.2422 17.2931 35.5225 19.5605 35.502H118.06C119.567 35.3657 121.509 35.0489 123.67 34.314C125.481 33.6962 126.968 32.947 128.116 32.2674L128.115 32.2643Z"/>
                                </svg>
                                <p className='bubble_text right_text' style={{color: fontColor}}>행복합시다</p>
                            </div>
                        </div>
                    </MotionWrapper>

                    <p className='interview_q'>Q. 마지막으로 전하고 싶은 이야기는?</p>
                    <div className='bubble_container'>
                        {/* 왼쪽 말풍선 */}
                        <MotionWrapper>
                            <div className='bubble_box left'>
                                <p className='name'>{groomName}</p>
                                <div className='bubble_wrapper'>
                                    <img className='bubble_left' src={bubbleGray} alt='Bubble Image' />
                                    <p className='bubble_text left_text'>축하해줘요</p>
                                </div>
                            </div>
                        </MotionWrapper>

                        {/* 오른쪽 말풍선 */}
                        <MotionWrapper>
                            <div className='bubble_box right'>
                                <p className='name'>{brideName}</p>
                                <div className='bubble_wrapper'>
                                    <svg className="bubble_right" width="142px" height="35px"  xmlns="http://www.w3.org/2000/svg">
                                        <path fill={`${msgColor}`} d="M128.115 32.2643C129.688 33.2147 132.441 34.6498 135.434 34.9713C136.154 35.0489 137.396 35.117 138.717 35.1028C140.046 35.0901 141.168 34.9967 142 34.9048C138.901 33.316 137.076 31.4406 136.118 30.2827C134.527 28.3613 134.317 27.205 134.271 26.584C134.203 25.6416 134.471 25.2662 134.75 23.8105C134.963 22.7001 134.986 21.5137 135.024 19.1884C135.073 16.1645 135.098 14.6518 134.888 13.5097C134.738 12.7019 134.392 10.8993 133.245 8.88763C131.452 5.73865 128.866 4.11505 127.705 3.407C125.175 1.86102 122.864 1.34781 121.685 1.09595C119.632 0.657185 118.01 0.638177 117.376 0.633425C113.309 0.603329 72.8556 0.536801 17.3734 0.501953C12.7466 1.06902 9.52431 2.4772 7.66046 3.47353C5.88686 4.42076 4.94017 5.21118 4.51358 5.58658C1.7818 7.99743 0.820343 10.7171 0.615265 11.3317C0.173904 12.656 0.0410156 13.8044 0 14.5678C0.0689087 17.4951 0.136169 20.4223 0.205078 23.3511C0.682526 24.8892 1.69812 27.3982 3.96722 29.7568C4.25928 30.0609 4.99266 30.7991 6.08702 31.6053C9.20601 33.9037 12.3151 34.588 14.1577 34.9729C15.444 35.2422 17.2931 35.5225 19.5605 35.502H118.06C119.567 35.3657 121.509 35.0489 123.67 34.314C125.481 33.6962 126.968 32.947 128.116 32.2674L128.115 32.2643Z"/>
                                    </svg>
                                    <p className='bubble_text right_text' style={{color: fontColor}}>많이많이♥</p>
                                </div>
                            </div>
                        </MotionWrapper>
                    </div>
                </div>
            </div>
            <img className='line_img' src={line} alt='Line Image'></img>
        </div>
    );
};

export default Interview_Spring;
