import React from 'react';
import "../../css/info_spring.css"
import MotionWrapper from '../MotionWrapper';


const Info_Spring = ({brideNameEn, groomNameEn, brideName, groomName, place, year, month, date, dayOfWeek, time, color}) => {
    return (
        <div className='info_container' style={{color : `${color}`}}>
            <MotionWrapper>
                <div className='info_name_box'>
                    <p>{groomNameEn}</p>
                    <svg width="13" height="11"  xmlns="http://www.w3.org/2000/svg">
                        <path fill={`${color}`} d="M6.51614 10.5384C6.51614 10.5384 5.11979 8.83348 3.69685 8.19405C2.27391 7.55462 0.997613 6.08221 0.997613 6.08221C0.997613 6.08221 -1.05294 4.0284 0.725662 1.39341C2.50427 -1.24205 6.09202 0.327379 6.51614 2.26492C6.51614 2.26492 7.23326 -0.641389 10.4137 0.172337C13.5942 0.986063 12.9455 4.41551 12.9455 4.41551C12.9455 4.41551 12.4432 6.25627 10.33 7.49636C8.21655 8.73645 6.51614 10.5384 6.51614 10.5384Z"/>
                    </svg>
                    <p>{brideNameEn}</p>
                </div>
            </MotionWrapper>

            <MotionWrapper>
                <div className='info_date_box'>
                    <p>{month}.{date}</p>
                    <p>{year}</p>
                </div>
            </MotionWrapper>
            
            <MotionWrapper>
                <div className='info_detail_name_box'>
                    <span>
                        <p>신랑</p> <p>{groomName}</p>
                    </span>
                    <span>
                        <p>신부</p> <p>{brideName}</p>
                    </span>
                </div>
            </MotionWrapper>

            <MotionWrapper>
                <div className='info_detail_date_box'>
                    <p>{year}.{month}.{date} {dayOfWeek} {time}</p>
                    <p>{place}</p>
                </div>
            </MotionWrapper>

        </div>
    );
};

export default Info_Spring;