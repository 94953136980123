import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import exportImg from '../css/export.png';
import cakeImg from '../css/guestBook/guest_2.png';
import cherryImg from '../css/guestBook/cherry_red.png';
import '../css/guestBookCake.css';
import moment from 'moment-timezone';
import ModalTemplate from '../component/ModalTemplate'; 
import xImg from '../css/x.png'
import GuestMessageCreateModal from '../component/Modal/GuestMessageCreateModal';
import GuestMessageValidateModal from '../component/Modal/GuestMessageValidateModal';
import axios from 'axios'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const GuestBookCake = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { mainPage } = useParams(); 

    const [guestMessages, setGuestMessages] = useState([]);
    const [totalMsgCnt, setTotalMsgCnt] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0); 
    const size = 10; // 한 페이지 당 항목 수

    const [leftTime, setLeftTime] = useState(""); 
    const [isWeddingOpen, setWeddingOpen] = useState(false); 
    const [isCherryModalOpen, setCherryModalOpen] = useState(false); 
    const [isModalOpen, setModalOpen] = useState(false); 
    const [isGuestMessageModalOpen, setGuestMessageModalOpen] = useState(false);
    const [isGuestMessageValidateModalOpen, setGuestMessageValidateModalOpen] = useState(false);
    const [clickedCherryIndex, setClickedCherryIndex] = useState(null);

    const [concept, setConcept] = useState("");
    const [invitationInfoId, setInvitationInfoId] = useState("");
    const [weddingDate, setWeddingDate] = useState("");
    const [btnBackColor, setBtnBackColor] = useState("");
    const [btnFontColor, setBtnFontColor] = useState("");
    const [fontType, setFontType] = useState("");

    const [selectedMessage, setSelectedMessage] = useState("");
    const [selectedName, setSelectedName] = useState("");

    const [brideName, setBrideName] = useState("");
    const [groomName, setGroomName] = useState("");

    const guestBook_info = `${groomName} ♥ ${brideName}에게 \n ${totalMsgCnt}개의 방명록이 전달됐어요!`

    const targetDate = moment.tz(weddingDate, "YYYY-MM-DD", "Asia/Seoul");

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleOpenGuestMessageModal = () => {
        setGuestMessageModalOpen(true);
    };


    const handleOpenValidateGuestMessageModal = () => {
        setGuestMessageValidateModalOpen(true);
    };

    const calculateTimeLeft = useCallback(() => {
        const now = moment.tz("Asia/Seoul");
        const difference = targetDate.diff(now);

        let timeLeft = "";
        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            if (days > 0) {
                timeLeft = `${days}일`;
            } else if (hours > 0) {
                timeLeft = `${hours}시간`;
            } else if (minutes > 0) {
                timeLeft = `${minutes}분`;
            } else {
                timeLeft = `${seconds}초`;
            }
        } else {
            timeLeft = "결혼식이 이미 진행되었습니다.";
            setWeddingOpen(true);
        }
        return timeLeft;
    }, [targetDate]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLeftTime(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [calculateTimeLeft]);

    const handleCherryClick = (index, id) => {
        if (isWeddingOpen) {
            setModalOpen(true);
            fetchGuestMessage(id); // 선택한 체리의 ID를 전달하여 API 호출
            setCherryModalOpen(false);
        } else {
            setCherryModalOpen(true);
            setModalOpen(false);
        }
        setClickedCherryIndex(index);
    };
    
    const closeCherryModal = () => {
        setCherryModalOpen(false);
        // setClickedCherryIndex(null);
    };

    const tmpCloseModal = () => {
        setModalOpen(false); 
        // setClickedCherryIndex(null);
    };

    const tmpCloseCreateMessageModal = () => {
        setGuestMessageModalOpen(false); 
    };

    const tmpCloseValidateMessageModal = () => {
        setGuestMessageValidateModalOpen(false); 
    };

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            console.log("response: ", response);
            if(response.status === 200){
                setConcept(response.data.data.concept); 
                setWeddingDate(response.data.data.weddingDate);
                setInvitationInfoId(response.data.data.id);
                setBrideName(response.data.data.brideNameKo.slice(1));
                setGroomName(response.data.data.groomNameKo.slice(1));
            }
        } catch (error) {
            console.error("API 요청 실패:", error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/guest_book/${mainPage}/all?page=${currentPage}&size=${size}`);
            setGuestMessages(response.data.data.content);
            setTotalMsgCnt(response.data.data.totalElements);
            setTotalPage(response.data.data.totalPages);
            console.log("all : ", response);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            await fetchInvitationInfoData();
            await fetchData();

            if(concept == "SPRING"){
                setBtnBackColor("#FFE8E7")
            } else if(concept == 'SUMMER'){
                setBtnBackColor("#505D89");
                setBtnFontColor("#ffffff");
            }  else if(concept == "FALL") {
                setBtnBackColor("#AB5934");
                setBtnFontColor("#fff")
                setFontType("JejuMyeongjo");
            } else if(concept == "WINTER") {
                setBtnBackColor("#000");
                setBtnFontColor("#fff")
                setFontType("JejuMyeongjo");
            }
        };
    
        fetchDataSequentially();
    }, []); 

    useEffect(() => {
        fetchData(); 
    }, []); 

    const handleSubmitMessage = () => {
        fetchData();
    };

    useEffect(() => {
        fetchData(currentPage); 
    }, [currentPage]); 

    const handleSlideChange = (swiper) => {
        const newIndex = swiper.activeIndex; 
        setCurrentPage(newIndex); 
        setClickedCherryIndex(null);
    };

    const fetchGuestMessage = async (id) => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/guest_book/${id}`);
            if (response.status === 200) {
                setSelectedMessage(response.data.data.message); // 첫 번째 <p> 태그에 들어갈 내용
                setSelectedName(response.data.data.name); // 두 번째 <p> 태그에 들어갈 내용
            }
        } catch (error) {
            console.error("Error fetching guest message:", error);
        }
    };

    return (
        <div style={{display: "flex", justifyContent:"center", height: "100vh"}} > 
            <div className="guestBook_container">
                <img className='back_button' src={xImg} alt='Close' onClick={handleGoBack} />
                <p className='guestBook_info' style={{fontFamily: fontType, lineHeight: "1.2em"}}>{guestBook_info}</p>
                <div className="cake_container">
                <Swiper cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true} 
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]} 
                    className='mySwiper'
                    speed={0} 
                    onSlideChange={handleSlideChange}
                >
                    {totalPage > 0 ? (
                        Array.from({ length: totalPage }, (_, index) => (
                            <SwiperSlide key={index}>
                                <img className='guest_cake_img' src={cakeImg} alt='Cake Image' />
                            </SwiperSlide>
                        ))
                    ) : (
                        <SwiperSlide>
                            <img className='guest_cake_img' src={cakeImg} alt='Default Cake Image' />
                        </SwiperSlide>
                    )}
                </Swiper>
                    {guestMessages.map((item, index) => (
                        <img
                            key={index}
                            id={`cherry_${index}`}
                            className="cherry_img"
                            src={cherryImg}
                            alt="Cherry Image"
                            onClick={() => handleCherryClick(index, item.id)} // item의 id를 전달
                            style={{
                                opacity: clickedCherryIndex === index ? 0.5 : 1
                            }}
                        />
                    ))}
                </div>
                <div className="guestBook_export" style={{display: "flex", width: "340px", justifyContent:"space-around", marginBottom: "29px", marginTop: "10px"}}>
                    <button style={{width : "155px", backgroundColor: btnBackColor, color: btnFontColor, fontFamily: fontType}} className='spring_btn' onClick={handleOpenGuestMessageModal}>작성하기</button>
                    <button style={{width : "155px", backgroundColor: btnBackColor, color: btnFontColor, fontFamily: fontType}} className='spring_btn' onClick={handleOpenValidateGuestMessageModal}>수정하기</button>
                </div>

                {isGuestMessageModalOpen && (
                    <GuestMessageCreateModal isModalOpen={isGuestMessageModalOpen} tmpCloseModal={tmpCloseCreateMessageModal} onSubmit={handleSubmitMessage} buttonBgColor={btnBackColor} fontColor={btnFontColor} invitationInfoId={invitationInfoId} fontFamilyKo={fontType}/>
                )}

                {isGuestMessageValidateModalOpen && (
                    <GuestMessageValidateModal isModalOpen={isGuestMessageValidateModalOpen} tmpCloseModal={tmpCloseValidateMessageModal} buttonBgColor={btnBackColor} fontColor={btnFontColor} fontFamilyKo={fontType}/>
                )}
                <span style={{display: "flex", alignItems: "center", width: "133px", justifyContent: "space-between"}}>
                    <img src={exportImg} alt='export Image' style={{width: "17px", height: "17px"}}/>
                    <p style={{fontSize : "13px", fontFamily: fontType}}>내 케이크 공유하기</p>
                </span>

                {/* 체리 클릭 시 모달 */}
                {isModalOpen && (
                    <ModalTemplate
                        isOpen={isModalOpen}
                        tmpCloseModal={tmpCloseModal}
                        backgroundColor="rgba(255, 255, 255, 0.5)"
                        fontFamilyKo={fontType}
                    >
                        <img style={{width: "53px"}} src={cherryImg} alt="Cherry Image" />
                        <p style={{fontSize: "12px", padding: "38px 0", fontFamily: fontType}}>{selectedMessage}</p> {/* response.data.message */}
                        <p style={{fontSize: "15px", paddingBottom: "20px", fontFamily: fontType}}>FROM. {selectedName}</p> {/* response.data.name */}
                        <button style={{width: "170px", margin: "20px 0, fontFamily: fontType"}} className="spring_btn">리스트로 보기</button>
                    </ModalTemplate>
                )}

                {/* 결혼식 진행 전 모달 */}
                {isCherryModalOpen&& (
                    <ModalTemplate
                    isOpen={isCherryModalOpen}
                    tmpCloseModal={closeCherryModal}
                    backgroundColor="rgba(255, 255, 255, 0.5)"
                    fontFamilyKo={fontType}
                    >
                    <p style={{fontSize: "10px", paddingBottom: "3px"}}>아직 열람할 수 없습니다.</p>
                    <p style={{fontSize: "10px"}}>방명록 열람까지 <span style={{color : "#FF8A83"}}>{leftTime}</span> 남았습니다.</p>
                </ModalTemplate>
                )} 
            </div>
        </div>
    );
};

export default GuestBookCake;
