import React from 'react';
import MotionWrapper from '../MotionWrapper';

const Info_Fall = ({brideName, groomName, place, year, month, date, dayOfWeek, time}) => {
    return (
        <div style={{fontFamily: "JejuMyeongjo", display: "flex", flexDirection: "column", textAlign:"center", alignItems: "center"}}>
            <MotionWrapper> 
                <div style={{display: "flex", width : "220px", justifyContent:"space-between", fontSize: "15px", margin: "45px 0"}}>
                    <span style={{display: "flex", width:"88px", justifyContent: 'space-around'}}>
                        <p>신랑</p> <p>최정호</p>
                    </span>
                    <span style={{display: "flex", width:"88px", justifyContent: 'space-around'}}>
                        <p>신부</p> <p>김가영</p>
                    </span>
                </div>
            </MotionWrapper>

            <MotionWrapper> 
                <span style={{fontSize: "14px", lineHeight: "1.3em"}}>
                    <p>{year}년 {month}월 {date}일 일요일 오후 {time}시</p>
                    <p style={{color: "#898989"}}>Sunday, March 9th, 2025 PM 12:00</p>
                </span>
            </MotionWrapper>

            <MotionWrapper> 
                <p style={{fontSize: "14px", marginTop: "25px", marginBottom : "96px"}}>{place}</p>
            </MotionWrapper>
        </div>
    );
};

export default Info_Fall;