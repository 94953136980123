import { useParams } from 'react-router-dom';
import Spring from './page/Spring';
import GuestBookCake from './page/GuestBookCake';
import Admin from './page/Admin';
import Summer from './page/Summer';
import Fall from './page/Fall';
import Kayoung from './page/Kayoung';

// 각 mainPage에 해당하는 컴포넌트를 설정
const pageComponents = {
    'sample_spring': Spring,
    'sample_summer': Summer,
    'sample_fall' : Fall,
    '1_luv': Kayoung,
};

function DynamicPage() {
    const { mainPage, subPage } = useParams();
    const Component = pageComponents[mainPage];  // mainPage에 해당하는 컴포넌트를 선택

    if (!Component) return <div>Page not found</div>;

    // subPage가 'guest'일 때 GuestBookCake 컴포넌트 렌더링
    if (subPage === 'guest') return <GuestBookCake />;
    
    // subPage가 'admin'일 때 Admin 컴포넌트 렌더링
    if (subPage === 'admin') return <Admin />;
    
    // subPage가 없으면 기본 Component를 렌더링
    return <Component />;
}

export default DynamicPage;
