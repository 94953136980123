import React from 'react';
import "../../css/spring/gallery_spring.css"
import "../../css/common.css"
import line from '../../css/line.png';
import Gallery_Cards from '../Gallery_Cards';


const Gallery_Spring = ({color, fontFamily, fontFamilyKo}) => {
    return (
        <div className='gallery_container'>
            <h3 className='spring_h3_title' style={{paddingTop: "76px", color: `${color}`, fontFamily: fontFamily}}>GALLERY</h3>
            <p className='p1_score_14' style={{fontFamily: fontFamilyKo}}> 사진을 클릭하시면 넘기며 보실 수 있습니다.</p>
            <Gallery_Cards/>
            <img className='line_img' src={line} alt='Line Image'></img>
        </div>
    );
};

export default Gallery_Spring;